require('./login');
require('./registration');
require('./nav');
require('./client');
require('./contracts');
require('./contract');
require('./bills');
require('./bill');
require('./confirm');
require('./footer');
require('./restorePassword');

module.exports = {

};