'use strict';

function contractCtrl($element, $localStorage, $translate, $window, $scope) {

  this.contract = null;
  this.detailToggled = false;
  $scope.detailToggled = false;

  this.alias = $localStorage.client_name;
  this.nombreApellidos = $localStorage.client_name + ' ' + $localStorage.client_lastname;
  this.telefono1 = $localStorage.client_phone1;
  this.fax = $localStorage.client_fax;
  this.email = $localStorage.client_email;
  this.icon = 'expand_more'

  var appWindow = angular.element($window);
  appWindow.bind('resize', function() {
    let detailContainer = $element[0].querySelector('.contract-detail');
    if($scope.detailToggled) {
      detailContainer.style.height = $element[0].querySelector('.contract-detail-item').clientHeight + 'px';
    }
  })

  /**
   * gets value from component binding
   *
   *@return {undefined}
   */
  this.$onInit = function () {
    this.contract = this.contract;
  }

  /**
   * detects changes on values from component binding
   *
   *@return {undefined}
   */
  this.$onChanges = function () {
    this.contract = this.contract;
  }


  /**
  * open selected contract
  */
  this.selected_contract = function() {
    if($localStorage.current_contract != null && $localStorage.current_contract == this.contract.contrato) {
      $localStorage.current_contract = null;
      this.toggleDetail();
    }
  }

  /**
   * Expand/colapse contracts details
   *
   *@return {undefined}
   */
  this.toggleDetail = function () {
    this.detailToggled = !this.detailToggled;
    $scope.detailToggled = this.detailToggled;
    let detailContainer = $element[0].querySelector('.contract-detail');
    if (this.detailToggled) {
      detailContainer.style.height = $element[0].querySelector('.contract-detail-item').clientHeight + 'px';
      this.icon = 'expand_less';
    } else {
      detailContainer.style.height = '0px';
      this.icon = 'expand_more';
    }
  }
}

module.exports = contractCtrl;