'use strict';

function billService ($q, $http, $localStorage) {

  return {
    getBills: getBills,
    getBillsByClientId: getBillsByClientId,
    getBillAsPDF: getBillAsPDF,
    getBillAsPDFByClientId: getBillAsPDFByClientId, 
    setHeaders: setHeaders
  };

  function getBills() {
    var def = $q.defer();
    var endpoint = CONFIG.ENDPOINTS_PRE.BILLS;
    var config = { headers: setHeaders() };
    $http.get(endpoint, config).then(function (response) {
      def.resolve(response);
    }).catch(function (err) {
      def.reject(err);
    });
    return def.promise;
  }

  function getBillAsPDF (billId) {
    var def = $q.defer();
    let endpoint = CONFIG.ENDPOINTS_PRE.BILLS_PDF + billId;
    var config = { headers: setHeaders() };
    $http.get(endpoint, config).then(function (response) {
      def.resolve(response);
    }).catch(function (err) {
      def.reject(err);
    });
    return def.promise;
  }

  function getBillAsPDFByClientId(clientId, billId) {
    var def = $q.defer();
    let endpoint = CONFIG.ENDPOINTS.BILLS_PDF + billId + '&client=' + clientId;
    var config = { headers: setHeaders() };
    $http.get(endpoint, config).then(function (response) {
      def.resolve(response);
    }).catch(function(err) {
      def.reject(err);
    });
    return def.promise;
  }

  function getBillsByClientId (clientId) {
    var def = $q.defer();
    var endpoint = CONFIG.ENDPOINTS_PRE.BILLS_CLIENT + clientId;
    var config = { headers: setHeaders() };
    $http.get(endpoint, config).then(function (response) {
      def.resolve(response);
    }).catch(function (err) {
      def.reject(err);
    });
    return def.promise;
  }

  function setHeaders() {
    var headers = {
      'Authorization': 'Bearer ' + $localStorage.id_token
    };
    return headers;
  }

}

module.exports = billService;