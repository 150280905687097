module.exports={
  "loadingTitle":                  "Var god vänta …",
  "close":                         "Stäng",
  "windowsAuthTitle":              "Autentisering",
  "invalid":                       "Fel",
  "mustMatch":                     "Måste matcha",
  "loginSocialButton":             "Logga in med {connection:title}",
  "signupSocialButton":            "Registera med {connection:title}",
  "networkError":                  "Vi kan inte nå servern.<br/>Var god och försök igen.",
  "noConnectionError":             "Ingen giltig anslutning kunde hittas.",
  "signin": {
    "title":                       "Logga in",
    "action":                      "Tillträde",
    "actionDomain":                "Logga in på {domain}",
    "all":                         "Visa alla",
    "strategyEmailEmpty":          "E-post är tomt.",
    "strategyEmailInvalid":        "Felaktig E-post.",
    "strategyDomainInvalid":       "Domänen {domain} är inte konfigurerad.",
    "signinText":                  "Logga in",
    "signupText":                  "Registrera",
    "forgotText":                  "Nollställ lösenord",
    "cancelAction":                "Avbryt",
    "footerText":                  "",
    "emailPlaceholder":            "E-post",
    "usernamePlaceholder":         "Användarnamn",
    "passwordPlaceholder":         "Lösenord",
    "separatorText":               "eller",
    "serverErrorText":             "Det skedde ett fel.",
    "returnUserLabel":             "Förra gången loggade du in med …",
    "domainUserLabel":             "Du är ansluten från ditt företagsnätverk …",
    "wrongEmailPasswordErrorText": "Fel E-post och lösenord.",
    "or":                          "… eller logga in med hjälp av",
    "loadingMessage":              "Logga in med {connection}...",
    "popupCredentials":            "Skriv in dina uppgifter i pop-up rutan",
    "userClosedPopup":             "Pop-up rutan stängd. Försök igen.",
    "userConsentFailed":           "Tillträdet medgavs ej. Försök igen."
  },
  "signup": {
    "description":                 "",
    "title":                       "Registera",
    "action":                      "Registera",
    "signinText":                  "Logga in",
    "signupText":                  "Registrera",
    "emailPlaceholder":            "E-post",
    "usernamePlaceholder":         "Användarnamn",
    "passwordPlaceholder":         "Skapa ett lösenord",
    "cancelAction":                "Avbryt",
    "headerText":                  "Var god och skriv din e-post och lösenord",
    "footerText":                  "",
    "serverErrorText":             "Något gick fel vid registeringen.",
    "userExistsErrorText":         "Denna användare finns redan.",
    "signupOnSSODomainErrorText":  "Detta domän {domain} har konfigurerats för singel användare och du kan tyvärr inte skapa ett konto. Försök att logga in istället.",
    "usernameInUseErrorText":      "Användarnamnet används redan.",
    "invalidPassword":             "Lösenordet är för osäkert.",

    "passwordStrength": {
      "nonEmpty": "Lösenord krävs",
      "lengthAtLeast": "Minimalt krävs %d karaktär",
      "shouldContain": "Bör innehålla:",
      "containsAtLeast" : "Innehåller minst %d följande %d tecken:",
      "lowerCase": "Gemensa tecken (små bokstäver) (a-z)",
      "upperCase": "Versala tecken (stora bokstäver) (A-Z)",
      "numbers": "Nummer (ex. 0-9)",
      "specialCharacters" : "Speciella tecken (ex. !@#$%^&*)",
      "identicalChars": "Inte fler än %d av samma tecken i följd (ex., \"%s\" är inte tillåtet)"
    }

  },
  "newReset": {
    "title":                       "Nollställ lösenord",
    "action":                      "Skicka",
    "emailPlaceholder":            "E-post",
    "cancelAction":                "Avbryt",
    "footerText":                  "",
    "successText":                 "Vi skickade precis ett e-post till dig så du kan nollställa ditt lösenord.",
    "headerText":                  "Var god skriv in din e-postadress. Vi kommer att skicka ett e-postmeddelande för att återställa ditt lösenord.",
    "serverErrorText":             "Något blev fel när vi försökte nollställa lösenordet.",
    "userDoesNotExistErrorText":   "Användare finns inte."
  },
  "reset": {
    "title":                       "Byt lösenord",
    "action":                      "Skicka",
    "emailPlaceholder":            "E-post",
    "passwordPlaceholder":         "Nytt lösenord",
    "repeatPasswordPlaceholder":   "Bekräfta lösenord",
    "cancelAction":                "Avbryt",
    "footerText":                  "",
    "successText":                 "Vi skickade precis ett e-post till dig så du kan nollställa ditt lösenord.",
    "enterSamePasswordText":       "Skriv samma lösenord.",
    "headerText":                  "Skriv din e-post och ett nytt lösenord. Vi kommer sedan skicka en bekräftelse.",
    "serverErrorText":             "Något blev fel när vi försökte nollställa lösenordet.",
    "userDoesNotExistErrorText":   "Användare finns inte.",
    "invalidPassword":             "Lösenordet är osäkert."
  }
}
