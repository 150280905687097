module.exports = {
  APP_NAME: 'GLP',
  COMPONENTS: {
    NAV: 'glpNav',
    NAV2: 'glpNav2',
    HEADER: 'glpHeader',
    CLIENT: 'glpClient',
    CLIENT_CONTRACTS: 'glpClientContracts',
    CONTRACT: 'glpContract',
    CLIENT_BILLS: 'glpClientBills',
    BILL: 'glpBill',
    FOOTER: 'glpFooter',
    REGISTRATION: 'glpRegistration',
    LOGIN: 'glpLogin',
    CONFIRM: 'glpConfirm',
    RESTOREPASSWORD: 'glpRestorePassword'
  },
  CONTROLLERS: {
    LOGIN: 'loginCtrl',
    REGISTRATION: 'registrationCtrl'
  },
  SERVICES: {
    AUTHSERVICE: 'authService',
    GLP: 'glpService',
    BILL: 'billService',
    CLIENT: 'clientService',
    CONTRACT: 'contractService'
  },
  ENDPOINTS: {
    MOCK_TOKEN: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0aXBvX2lkZW50aWZpY2FjaW9uIjoiTklGIiwiaWRlbnRpZmljYWNpb24iOiIwMDAwMDAyM1QiLCJ0aXBvX2NvbnRyYXRvIjoiQ1VQUyIsImNvbnRyYXRvIjoiRVMwMjIwMDQyMzAwMDQ4NjA0S1AiLCJlbWFpbCI6InVzdWFyaW9AZW1haWwuY29tIiwidXNyX3Bhc3N3b3JkIjoidW5fcGFzc3dvcmQiLCJhcHBfbWV0YWRhdGEiOnsicm9sIjoiQ0xJRU5UIn19.MEmve9h3DAWtjF9xS7X3cwywM0L_6oA3CPGp2s04V5M',
    USER_INFO: 'https://gniinnova-dev.eu.auth0.com/userinfo',
    CLIENT_INFO: 'https://web-api.webglp-dev.gniinnova.com/v1/info_client',
    CLIENT_BY_ID: 'https://web-api.webglp-dev.gniinnova.com/v1/info_client?client=',
    CLIENT_POST: 'https://registro-api.webglp-dev.gniinnova.com/v1/register',
    CLIENT_UPDATE_PASSWORD: 'https://registro-api.webglp-dev.gniinnova.com/v1/update',
    CLIENT_CHANGE_PASSWORD: 'https://registro-api.webglp-dev.gniinnova.com/v1/recover',
    CLIENT_DELETE: 'https://registro-api.webglp-dev.gniinnova.com/v1/deregister',
    BILLS: 'https://web-api.webglp-dev.gniinnova.com/v1/invoices',
    BILLS_CLIENT: 'https://web-api.webglp-dev.gniinnova.com/v1/invoices?client=',
    BILLS_PDF: 'https://documentum-api.webglp-dev.gniinnova.com/v1/invoice_doc?invoice=',
    CONTRACTS: 'https://web-api.webglp-dev.gniinnova.com/v1/contracts',
    CONTRACTS_CLIENT: 'https://web-api.webglp-dev.gniinnova.com/v1/contracts?client=',
    API_MANAGEMENT: 'https://carlesfores.eu.auth0.com/oauth/token',
    GET_CONTRACTS_MOCK: 'https://web-api.webglp-dev.gniinnova.com/v1/contracts',
    GET_CLIENT_MOCK: 'https://web-api.webglp-dev.gniinnova.com/v1/info_client',
    POST_CLIENT_MOCK: 'https://registro-api.webglp-dev.gniinnova.com/v1/register',
    CONTRACTS_MOCK: 'https://web-api.webglp-dev.gniinnova.com/v1/contracts',
    INFO_CUPS: 'http://www.redesglp.gasnatural.com/es/inicio/clientes/1297117100872/facturacion.html',
    LEGAL_INFORMATION: 'https://www.gnredesglp.es/glp-inicio-2/nota-legal/'
  },
  ENDPOINTS_PRE: {
    USER_INFO: 'https://gniinnova-dev.eu.auth0.com/userinfo',
    CLIENT_INFO: 'https://web-api.webglp-pre.gniinnova.com/v1/info_client',
    CLIENT_BY_ID: 'https://web-api.webglp-pre.gniinnova.com/v1/info_client?client=',
    CLIENT_POST: 'https://registro-api.webglp-pre.gniinnova.com/v1/register',
    CLIENT_UPDATE_PASSWORD: 'https://registro-api.webglp-pre.gniinnova.com/v1/update',
    CLIENT_CHANGE_PASSWORD: 'https://registro-api.webglp-pre.gniinnova.com/v1/recover',
    CLIENT_DELETE: 'https://registro-api.webglp-pre.gniinnova.com/v1/deregister',
    BILLS: 'https://web-api.webglp-pre.gniinnova.com/v1/invoices',
    BILLS_CLIENT: 'https://web-api.webglp-pre.gniinnova.com/v1/invoices?client=',
    BILLS_PDF: 'https://documentum-api.webglp-pre.gniinnova.com/v1/invoice_doc?invoice=',
    CONTRACTS: 'https://web-api.webglp-pre.gniinnova.com/v1/contracts',
    CONTRACTS_CLIENT: 'https://web-api.webglp-pre.gniinnova.com/v1/contracts?client=',
    API_MANAGEMENT: 'https://carlesfores.eu.auth0.com/oauth/token',
    INFO_CUPS: 'http://www.redesglp.gasnatural.com/es/inicio/clientes/1297117100872/facturacion.html',
    LEGAL_INFORMATION: 'https://www.gnredesglp.es/glp-inicio-2/nota-legal/'
  },
  AUTH: {
    DOMAIN: 'carlesfores.eu.auth0.com',
    CLIENT_ID: '78QtzHK4OHL3BZOAgPqKNhSyjzedxQ7m',
    LOGIN_URL: '/login',
    CONNECTION: 'Username-Password-Authentication',
    REDIRECT_URI_SERVER: 'http://basetis-glp.s3-website-eu-west-1.amazonaws.com/#!/bills',
    REDIRECT_URI_SERVER2: 'http://glpera-dev.gniinnova.com/#!/bills',
    REDIRECT_URI_LOCALHOST: 'http://localhost:3000/#!/bills',
    RESPONSE_TYPE: 'token id_token',
    AUDIENCE: 'https://carlesfores.eu.auth0.com/userinfo',
    SCOPE: 'openid profile read:users read:contacts read:user_idp_tokens',
    SSO: 'true'
  },
  AUTH_GLP: {
    RO: 'https://gniinnova.auth0.com/oauth/ro',
    TOKEN: 'https://gniinnova.auth0.com/oauth/token',

    // NEW DOMAIN
    DOMAIN: 'gniinnova-dev.eu.auth0.com',
    CLIENT_ID: 'wnxiTtNVUvgIX3AvSm5s1DJ0Y0oSygWa',

    // OLD DOMAIN
    //DOMAIN: 'gniinnova.auth0.com',
    //CLIENT_ID: 'tPtXssUdJyBSMPgWP7p9JHHL0VrZD72i',

    LOGIN_URL: '/login',
    CONNECTION: 'glpera-database-connection',
    REDIRECT_URI_SERVER2: 'http://glpera-dev.gniinnova.com/#!/bills',
    REDIRECT_URI_LOCALHOST: 'http://localhost:3000/#!/bills',
    RESPONSE_TYPE: 'token id_token',
    AUDIENCE: 'https://gniinnova.auth0.com/userinfo',
    SCOPE: 'id_token openid profile app_metadata',
    SSO: 'true',
    GRANT_TYPE: 'password',
    USER_ROLE_URL: 'http://glpera.gniinnova.com/app_metadata',
    USER_CREATED_AT: 'http://glpera.gniinnova.com/created_at'
  },
  API_MANAGEMENT: {
    GRANT_TYPE: 'client_credentials',
    CLIENT_ID: 'VF0O2Fd9fOxMHNeMlTkQS1n41zbn0U2c',
    CLIENT_SECRET: '-5MpjZSnLt0VUcBhr7xgeQC7YC-RaX9qlhMRGzfPP9Cqisf8tsrGjzin8wPTXvQp',
    AUDIENCE: 'https://carlesfores.eu.auth0.com/api/v2/'
  }
};
