'use strict';

function contractsCtrl($scope, $localStorage, $translate, contractService, clientService) {
  $scope.$emit('userinfo');
  this.response = false;
  this.findContracts = $translate.instant("CONTRACTS.FIND_CONTRACTS");
  this.atr = false;
  this.getContracts = getContracts.bind(this);
  this.getClientInfoATR = getClientInfoATR.bind(this);
  this.getContractsByClientIdATR = getContractsByClientIdATR.bind(this);
  this.clientError = null;
  this.clientId = null;

  this.loading = false;

  /**
  * on initialisation get user contracts
  */
  this.$onInit = function () {
    if($localStorage.user_role === 'ADMIN') {
      this.atr = true;
      if($localStorage.admin_search) {
        this.clientId = $localStorage.admin_search;
        this.getClientInfoATR();
      }
    }
    else this.getContracts();
    //if($localStorage.current_contract != null) {
     // this.contracts
    //}
  }

  this.$onChanges = function () {
  }

  /**
   * get client contracts
   *
   *@return {undefined}
   */
  function getContracts() {
    this.loading = true;
    contractService.getContracts().then((response) => {
      this.loading = false;
      this.contracts = response;
      this.response = true;
      //console.log(this.contracts);
    }).catch((err) => {
      this.loading = false;
      //console.warn(err.data);
    });
  }

  /**
  * get client info for atr users
  */
  function getClientInfoATR() {
    this.client = null;
    this.contracts = [];
    this.clientError = null;
    if (this.clientId) {
      let id = this.clientId.toUpperCase();
      $localStorage.admin_search = id;
      this.loading = true;
      clientService.getClientById(id).then((response) => {
        this.loading = false;
        this.clientError = null;
        this.client = response.data;
        clientService.setClientData(this.client);
        this.getContractsByClientIdATR();
      }).catch((err) => {
        this.loading = false;
        this.clientError = $translate.instant("ATR.ERROR");
        //console.warn(err);
      });
    } else {
      delete $localStorage.admin_search;
    }
  }

  /**
  * get user contracts for atr users
  */
  function getContractsByClientIdATR() {
    this.billsContracts = [];
    let id = this.clientId.toUpperCase();
    this.contract = true;
    this.loading = true;
    contractService.getContractsByClientId(id).then((response) => {
      this.loading = false;
      this.contract = false;
      this.contracts = response.data;
      this.response = true;
    }).catch((err) => {
      this.loading = false;
      //console.warn(err);
    });
  }

  /**
  * Check if enter key is pressed
  */
  this.checkEnterPress = function($event) {
    var keyCode = $event.which || $event.keyCode;
    if(keyCode === 13) {
      this.getClientInfoATR();
    }
  }

}

module.exports = contractsCtrl;