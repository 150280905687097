const angular = require('angular');
const myApp = angular.module(CONFIG.APP_NAME);

myApp.config(function ($stateProvider, $urlRouterProvider, $locationProvider) {
  $urlRouterProvider.otherwise("/invoices");
  $stateProvider
    .state('home', {
      templateUrl: "views/home.html",
      resolve: {
        userSesion: ['$q', '$location', 'authService', function ($q, $location, authService) {
          var def = $q.defer();
          if (!authService.isAuthenticated()) {
            $location.path('/login');
          } else {
            if($location.$$path==='/login') {
              $location.path('/invoices');
            }
            def.resolve();
          }
          return def.promise;
        }]
      }
    })
    .state('home.contracts', {
      url: "/contracts",
      templateUrl: "views/contracts.html"
    })
    .state('home.bills', {
      url: "/invoices",
      templateUrl: "views/bills.html"
    })
    .state('home.client', {
      url: "/account",
      templateUrl: "views/client.html"
    })
    .state('login', {
      url: "/login",
      templateUrl: "views/login.html",
      resolve: {
        userSesion: ['$q', '$location', 'authService', function ($q, $location, authService) {
          var def = $q.defer();
          if (authService.isAuthenticated()) {
            $location.path('/invoices');
          } else {
            def.resolve();
          }
          return def.promise;
        }]
      }
    })
    .state('restorePassword', {
      url: "/restorePassword",
      templateUrl: "views/restorePassword.html"
    })
    .state('registration', {
      url: "/registration",
      templateUrl: "views/registration.html"
    });

  //$locationProvider.html5Mode({
  //  enabled: true,
  //  requireBase: false
  //});

});