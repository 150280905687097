module.exports = (function anonymous(locals, filters, escape, rethrow
/*``*/) {
escape = escape || function (html){
  return String(html)
    .replace(/&(?!#?[a-zA-Z0-9]+;)/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#39;')
    .replace(/"/g, '&quot;');
};
var buf = [];
with (locals || {}) { (function(){ 
 buf.push('<div tabindex="0"\n		data-strategy="', escape((2,  name )), '"\n		title="', escape((3,  username ? (username + ' (' + title + ')') : title )), '"\n		class="a0-zocial a0-block a0-', escape((4,  css )), ' ', escape((4,  locals.imageicon ? 'a0-image-icon' : '' )), '">\n	<span>', escape((5,  username || title )), '</span>\n</div>'); })();
} 
return buf.join('');
})