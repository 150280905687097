'use strict';

function billsCtrl($scope, $localStorage, $translate, clientService, billService) {
  $scope.$emit('userinfo');
  this.bill = null;
  this.selectedBill = null;
  this.selectedIndex = null;
  this.startDateString = null;
  this.endDateString = null;
  this.startDate = null;
  this.endDate = null;
  this.expanse = false;
  this.options = [$translate.instant("BILLS.BILL_DATE"), $translate.instant("BILLS.EXPIRATION_DATE")];
  this.reverse = true;
  this.order = 'factura';
  this.icon = 'expand_more';
  this.billsContracts = [];
  this.clientId = null;
  this.client = null;
  this.clientError = null;
  this.getBills = getBills.bind(this)
  this.getClientInfoATR = getClientInfoATR.bind(this);
  this.getBillsByClientIdATR = getBillsByClientIdATR.bind(this);
  this.findInvoices = $translate.instant("BILLS.FIND_INVOICES");
  this.atr = false;

  this.loading = false;

  /**
  * On initialisation gets client bills
  */
  this.$onInit = function () {
    if($localStorage.user_role === 'ADMIN') {
      this.atr = true;
      if($localStorage.admin_search) {
        this.clientId = $localStorage.admin_search;
        this.getClientInfoATR();
      }
    } else {
      this.atr = false;
      this.getBills();
    }
  }

  this.$onChanges = function () {
  }

  /**
  * Selects bill clicked
  */
  this.selectBill = function (index, bill) {
    if (this.selectedIndex === null) {
      this.selectedIndex = index;
    }
    else if (this.selectedIndex === index) {
      this.selectedIndex = null;
    }
    else {
      this.selectedIndex = index;
    }
    this.selectedBill = bill;

    if (!this.expanse) {
      this.expanse = true;
    }

  }

  /**
  * Converts date string to date format
  */
  this.stringToDate = function() {
    if(this.startDateString) this.startDate = new Date(this.startDateString);
    else this.startDate = null;
    if(this.endDateString) this.endDate = new Date(this.endDateString);
    else this.endDate = null;
  }

  /*this.expanseBill = function () {
    if (!this.expanse && this.selectedBill) {
      this.expanse = true;
    } else {
      this.expanse = false;
    }
  }*/

  this.changeDateType = function () {
    this.startDate = null;
    this.endDate = null;
  }

  /**
  * Changes order variable
  */
  this.orderBy = function (order) {
    this.reverse = (this.order === order) ? !this.reverse : false;
    this.order = order;
    if (this.order === order) {
      this.icon = (this.icon === 'expand_more') ? 'expand_less' : 'expand_more';
    }
  }

  /**
   * get client bills
   *
   *@return {undefined}
   */
  function getBills() {
    if (!this.clientId) {
      this.loading = true;
      billService.getBills().then((response) => {
        this.loading = false;
        this.bills = response.data;
        for (var i = 0; i < this.bills.length; i++) {
          this.bills[i].importe = Number(this.bills[i].importe.replace(/,/g, '.'));
          let date = this.bills[i].fecha_factura;
          let correct_factura = date.slice(6, 10) + date.slice(3,5) + date.slice(0,2);
          this.bills[i].correct_factura = correct_factura;
          let date_venc = this.bills[i].fecha_vencimiento;
          let correct_vencimiento = date_venc.slice(6, 10) + date_venc.slice(3,5) + date_venc.slice(0,2);
          this.bills[i].correct_vencimiento = correct_vencimiento;
          let aux = this.bills[i].contrato;
          let repeat = false;
          for(var n = 0; n < this.billsContracts.length; n++) {
            if(aux.indexOf(this.billsContracts[n].contrato) == 0) {
              repeat = true;
            }
          }
          if(!repeat) {
            this.billsContracts.push(this.bills[i]);
          }
        }
      }).catch((err) => {
        this.loading = false;
        //console.warn(err);
      });
    }
  }


  /**
  * get client info for atr user
  */
  function getClientInfoATR() {
    this.client = null;
    this.bills = [];
    this.clientError = null;

    if (this.clientId) {
      let id = this.clientId.toUpperCase();
      $localStorage.admin_search = id;
      this.loading = true;
      clientService.getClientById(id).then((response) => {
        this.loading = false;
        this.clientError = null;
        this.client = response.data;
        this.getBillsByClientIdATR();
      }).catch((err) => {
        this.loading = false;
        this.clientError = $translate.instant("ATR.ERROR");
        //console.warn(err);
      });
    } else {
      delete $localStorage.admin_search;
    }
  }

  /**
  * get client bills for atr user
  */
  function getBillsByClientIdATR() {
    this.bills = [];
    this.billsContracts = [];
    let id = this.clientId.toUpperCase();
    this.loading = true;
    billService.getBillsByClientId(id).then((response) => {
      this.loading = false;
      this.bills = response.data;
      for (var i = 0; i < this.bills.length; i++) {
        this.bills[i].importe = Number(this.bills[i].importe.replace(/,/g, '.'));
        let date = this.bills[i].fecha_factura;
        let correct_factura = date.slice(6, 10) + date.slice(3,5) + date.slice(0,2);
        this.bills[i].correct_factura = correct_factura;
        let date_venc = this.bills[i].fecha_vencimiento;
        let correct_vencimiento = date_venc.slice(6, 10) + date_venc.slice(3,5) + date_venc.slice(0,2);
        this.bills[i].correct_vencimiento = correct_vencimiento;
        let aux = this.bills[i].contrato;
        let repeat = false;
        for(var n = 0; n < this.billsContracts.length; n++) {
          if(aux.indexOf(this.billsContracts[n].contrato) == 0) {
            repeat = true;
          }
        }
        if(!repeat) {
          this.billsContracts.push(this.bills[i]);
        }
      }
    }).catch((err) => {
      this.loading = false;
      //console.warn(err);
    });
  }

  /**
  * Checks if enter key is pressed
  */
  this.checkEnterPress = function($event) {
    var keyCode = $event.which || $event.keyCode;
    if(keyCode === 13) {
      this.getClientInfoATR();
    }
  }

}

module.exports = billsCtrl;