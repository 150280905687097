'use strict';

function registrationCtrl($state, $translate, $mdDialog, clientService) {
  this.glpService;

  this.show_CUPS = show_CUPS; 

  this.status = 'validate';
  this.errorMessages = [];
  this.error = false;
  this.errorSelectNif = false;
  this.errorSelectCups = false;
  this.errorNif = false;
  this.errorCups = false;
  this.errorEmail = false;
  this.errorPassword = false;
  this.errorExistingEmail = false;
  this.hide = false;
  this.showConfirm_registration = showConfirm_registration;


  this.nif = null;
  this.cups = null;
  this.email = null;
  this.password = null;
  this.confirmEmail = null;
  this.confirmPassword = null;

  this.nif_types = [
    $translate.instant('REGISTRATION.NIF'),
    $translate.instant('REGISTRATION.NIE'),
    $translate.instant('REGISTRATION.PASSPORT')
  ];

  this.cups_types = [
    $translate.instant('REGISTRATION.CUPS'),
    $translate.instant('REGISTRATION.CONTRACT')
  ];

  this.cups_types_spanish = ['CUPS', 'CONTRATO'];

  this.emailText = $translate.instant('REGISTRATION.DATA.MAIL');
  this.confEmailText = $translate.instant('REGISTRATION.DATA.REPEAT_MAIL');
  this.passText = $translate.instant('REGISTRATION.DATA.PASSWORD');
  this.confPassText = $translate.instant('REGISTRATION.DATA.REPEAT_PASSWORD');

  this.infoButtonText = $translate.instant('REGISTRATION.DATA.CUPS_INFO');
  this.infoCUPS = CONFIG.ENDPOINTS.INFO_CUPS;
  this.legalInfo = CONFIG.ENDPOINTS.LEGAL_INFORMATION;

  this.NIFplaceHolder = $translate.instant('REGISTRATION.VALIDATION.NIF');
  this.CUPSplaceHolder = $translate.instant('REGISTRATION.VALIDATION.CUPS');

  this.invalidEmailAddress = 'invalid email address';


  this.SelectedNif = null;
  this.SelectedCups = null;

  this.loading = false;

  /**
   * Switch action depending on state of client registration
   *
   * @return {undefined}
   */
  this.action = function (status) {
    this.errorMessages = [];
    this.error = false;
    this.errorSelectNif = false;
    this.errorSelectCups = false;
    this.errorNif = false;
    this.errorCups = false;
    this.errorEmail = false;
    this.errorPassword = false;
    this.errorExistingEmail = false;
    this.hide = false;

    if (this.inputsValidator(status)) {
      switch (status) {
        case 'validate':
          this.status = 'data';
          break;

        case 'data':
          this.loading = true;

          clientService.postClient(this.SelectedNif,  this.nif, this.SelectedCups, this.cups, this.email, this.password).then((response) => {
            this.loading = false;
            this.showConfirm_registration();
          }).catch((err) => {
            this.loading = false;
            if (err.data.description == this.invalidEmailAddress) {
              this.errorMessages.push($translate.instant('REGISTRATION.INVALID_EMAIL'));
              this.errorEmail = true;
              this.error = true;
            }
            else if (err.status == 400) {
              this.errorMessages.push($translate.instant('REGISTRATION.EXISTING_EMAIL'));
              this.error = true;
              this.errorExistingEmail = true;
            }
            else if (err.status == 403) {
              this.errorMessages.push($translate.instant('API_ERROR.403'));
              this.error = true;
              this.errorExistingEmail = true;
            }
          });
          break;
        default:
      }
    }

    if (this.errorMessages.length) {
      this.error = true;
    }
  }

  /**
  * show cups info dialog
  */
  function show_CUPS($event) {
    $mdDialog.show({
      templateUrl: './components/registration/template_cups.html',
      controller: dialogController,
      parent: angular.element(document.body),
      targetEvent: $event,
      ariaLabel: 'Confirmation alert',
      clickOutsideToClose: true,
      locals: {
      }
    }).then((answer) => {
      
    })

    function dialogController($scope, $mdDialog) {
      $scope.answer = function (answer) {
        $mdDialog.hide(answer);
      }
    }
    
  }

  /**
  * show registration confirmation dialog
  */
  function showConfirm_registration($event) {
    this.title = $translate.instant("CONFIRM.TITLE_REGISTER");
    this.accept = $translate.instant("CONFIRM.RETURN_LOGIN");
    $mdDialog.show({
      templateUrl: './components/confirm/template2.html',
      controller: dialogController,
      parent: angular.element(document.body),
      targetEvent: $event,
      ariaLabel: 'Confirmation alert',
      clickOutsideToClose: false,
      locals: {
        title: this.title,
        accept: this.accept
      }
    }).then((answer) => {
      if (answer == 'save') {
        this.error = false;
        this.errorMessages = [];
        this.returnToLogin();
      }
      else {
        this.error = false;
        this.errorMessages = [];
      }
    })
    function dialogController($scope, $mdDialog, title, accept) {
      $scope.title = title;
      $scope.accept = accept;

      $scope.answer = function (answer) {
        $mdDialog.hide(answer);
      }
    }
  }

  /**
   * Return to login state
   *
   *@return {undefined}
   */
  this.returnToLogin = function () {
    $state.go('login');
  }

  /**
   * Return to client validation
   *
   *@return {undefined}
   */
  this.returnToClientValidation = function (ev) {
    ev.preventDefault();
    this.status = 'validate';
    this.error = false;
  }

  /**
   * Return to client data
   *
   *@return {undefined}
   */
  this.returnToClientData = function () {
    this.status = 'data';
    this.confirmEmail = null;
    this.password = null;
    this.confirmPassword = null;
    this.error = false;
  }

  /**
  * hide error message
  */
  this.hideMessage = function () {
    this.hide = true;
  }

  /**
  * input validations
  */
  this.inputsValidator = function (status) {
    var valid = true;

    switch (status) {
      case 'validate':
        if (this.nif == null || this.cups == null) {
          valid = false;
          this.errorMessages.push($translate.instant('REGISTRATION.MANDATORY_FIELDS'));
          if (this.nif == null) this.errorNif = true;
          if (this.cups == null) this.errorCups = true;
          if (this.SelectedNif == null) this.errorSelectNif = true;
          if (this.SelectedCups == null) this.errorSelectCups = true;

        } else {
          if (this.SelectedNif===this.nif_types[0] && !this.validateNIF()) {
            valid = false;
            this.errorNif = true;
          }

        }
        break;
      case 'data':
        if (this.email == null || this.confirmEmail == null || this.password == null || this.confirmPassword == null) {
          valid = false
          this.errorMessages.push($translate.instant('REGISTRATION.MANDATORY_FIELDS'));
          if (this.email == null || this.confirmEmail == null) this.errorEmail = true;
          if (this.password == null || this.confirmPassword == null) this.errorPassword = true;
        } else {
          if (this.email !== this.confirmEmail) {
            this.errorMessages.push($translate.instant('REGISTRATION.EMAIL_MATCH'));
            valid = false;
            this.errorEmail = true;
          }
          if (this.password !== this.confirmPassword) {
            this.errorMessages.push($translate.instant('REGISTRATION.PASSWORD_MATCH'));
            valid = false;
            this.errorPassword = true;
          }
          else if (this.password.length < 6) {
            this.errorMessages.push($translate.instant('REGISTRATION.PASSWORD_LENGTH'));
            valid = false;
            this.errorPassword = true;
          }
        }
        break;
      default:
    }

    return valid;
  }

  /**
   * Validate NIF
   *
   * @returns {boolean}
   */
  this.validateNIF = function () {
    var valid = true;
    var numero
    var letr
    var letra
    var expresion_regular_dni
    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;

    if (expresion_regular_dni.test(this.nif) == true) {
      numero = this.nif.substr(0, this.nif.length - 1);
      letr = this.nif.substr(this.nif.length - 1, 1);
      numero = numero % 23;
      letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
      letra = letra.substring(numero, numero + 1);
      if (letra != letr.toUpperCase()) {
        this.errorMessages.push($translate.instant('REGISTRATION.NIF_LETTER'));
        valid = false;
      }
    } else {
      this.errorMessages.push($translate.instant('REGISTRATION.NIF_FORMAT'));
      valid = false;
    }
    return valid;
  }

  /**
  * open info cups dialog
  */
  this.openInfoCUPS = function () {
    this.show_CUPS();
  }

  /**
  * open legal info link
  */
  this.openLegalInfo = function () {
    window.open(this.legalInfo, '_blank')
  }

}

module.exports = registrationCtrl;