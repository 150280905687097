const angular = require('angular');

const contractCtrl = require('./controller');
contractCtrl.$inject = ['$element', '$localStorage', '$translate', '$window', '$scope'];

module.exports = angular.module(CONFIG.APP_NAME).component(CONFIG.COMPONENTS.CONTRACT, {
  bindings: {
    contract: '='
  },
  transclude: true,
  controller: contractCtrl,
  templateUrl: './components/contract/template.html'
});