module.exports = (function anonymous(locals, filters, escape, rethrow
/*``*/) {
escape = escape || function (html){
  return String(html)
    .replace(/&(?!#?[a-zA-Z0-9]+;)/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#39;')
    .replace(/"/g, '&quot;');
};
var buf = [];
with (locals || {}) { (function(){ 
 buf.push('');1; var icon_class = !use_big_buttons ? 'a0-icon' : 'a0-block' ; buf.push('\n<div tabindex="0"\n		data-strategy="', escape((3,  name )), '"\n		title="', escape((4,  title )), '"\n		class="a0-zocial ', escape((5,  icon_class )), ' a0-', escape((5,  css )), ' ', escape((5,  locals.imageicon ? 'a0-image-icon' : '' )), '" dir="ltr">\n\n\n	<span>', escape((8,  title )), '</span>\n</div>'); })();
} 
return buf.join('');
})