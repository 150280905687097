const app = angular.module(CONFIG.APP_NAME);

app.filter('billName', function () {
  return function (items, billName) {
    if (billName) {
      var filtered = [];
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if (item.factura.toLowerCase().indexOf(billName.toLowerCase()) !== -1) {
          filtered.push(item);
        }
      }
      return filtered;
    } else {
      return items;
    }
  };
});

app.filter('billInContract', function () {
  return function (items, contract) {
    if (contract) {
      var filtered = [];
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if (item.contrato.toLowerCase().indexOf(contract.toLowerCase()) !== -1) {
          filtered.push(item);
        }
      }
      return filtered;
    } else {
      return items;
    }
  };
});

app.filter('contractName', function () {
  return function (items, contractName) {
    if (contractName) {
      var filtered = [];
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if (item.contrato.toLowerCase().indexOf(contractName.toLowerCase()) !== -1 || item.direccion_suministro.toLowerCase().indexOf(contractName.toLowerCase()) !== -1) {
          filtered.push(item);
        }
      }
      return filtered;
    } else {
      return items;
    }
  };
});

app.filter('contractDirection', function () {
  return function (items, contractDirection) {
    if (contractDirection) {
      var filtered = [];
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if (item.direccion_suministro.toLowerCase().indexOf(contractDirection.toLowerCase()) !== -1) {
          filtered.push(item);
        }
      }
      return filtered;
    } else {
      return items;
    }
  };
});

app.filter('billStartDate', function () {
  return function (items, startDate, dateTypes) {
    if (startDate && dateTypes) {
      var filtered = [];
      for (var i = 0; i < items.length; i++) {
        var item = items[i];

        if (dateTypes == "Fecha Factura") {
          var dateParts = (item.fecha_factura).split("/");
          var date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
          if (date >= startDate) {
            filtered.push(item);
          }

        } else if (dateTypes == "Fecha vencimiento") {
          var dateParts = (item.fecha_vencimiento).split("/");
          var date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

          if (date >= startDate) {
            filtered.push(item);
          }
        }
      }
      return filtered;
    } else {
      return items;
    }
  }
});

app.filter('billEndDate', function () {
  return function (items, endDate, dateTypes) {
    if (endDate && dateTypes) {
      var filtered = [];
      for (var i = 0; i < items.length; i++) {
        var item = items[i];

        if (dateTypes == "Fecha Factura") {
          var dateParts = (item.fecha_factura).split("/");
          var date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

          if (date <= endDate) {
            filtered.push(item);
          }

        } else if (dateTypes == "Fecha vencimiento") {
          var dateParts = (item.fecha_vencimiento).split("/");
          var date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

          if (date <= endDate) {
            filtered.push(item);
          }
        }
      }
      return filtered;
    } else {
      return items;
    }
  }
});

app.filter('num', function () {
  return function (input) {
    let num = Number(input.replace(/,/g, '.'));
    return num;
  }
});

module.exports = {};