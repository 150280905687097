window.CONFIG = require('./config');

import angular from 'angular';
window.auth0 = require('auth0-js');

import '@uirouter/angularjs'
import 'angular-auth0';
import 'angular-mocks';

// Angular Material
import 'angular-material';
import 'angular-aria';
import 'angular-animate';
import 'angular-messages';
import 'angular-sanitize';

// Angular translate
import 'angular-translate';
import 'angular-translate-loader-static-files';
import 'angular-translate-storage-cookie';
import 'angular-translate-storage-local';

// ngStorage $localStorage, $sessionStorage
import 'ngstorage';
import 'date-input-polyfill';

let app = angular.module(CONFIG.APP_NAME, [
  'ui.router',
  'auth0.auth0',
  'ngSanitize',
  'ngMaterial',
  'ngMessages',
  'ngStorage',
  'pascalprecht.translate'
]);

require('./lib')
require('./services');
require('./filters');
require('./controllers');
require('./components');
require('./routes');

// Configuration of the app
app.config(function ($logProvider, angularAuth0Provider, $translateProvider, $mdGestureProvider) {
  $mdGestureProvider.skipClickHijack();
  $logProvider.debugEnabled(true);
  $translateProvider.useStaticFilesLoader({
    prefix: './languages/',
    suffix: '.json'
  });
  $translateProvider.preferredLanguage('es');
  $translateProvider.useSanitizeValueStrategy('sanitizeParameters');

  // Initialization for the angular-auth0 library
  angularAuth0Provider.init({
    clientID: CONFIG.AUTH_GLP.CLIENT_ID,
    domain: CONFIG.AUTH_GLP.DOMAIN,
    //responseType: CONFIG.AUTH_GLP.RESPONSE_TYPE,
    audience: CONFIG.AUTH_GLP.AUDIENCE,
    grantType: "http://auth0.com/oauth/grant-type/password-realm",
    redirectUri: CONFIG.AUTH_GLP.REDIRECT_URI_SERVER2,
    scope: CONFIG.AUTH_GLP.SCOPE,
    grantType: CONFIG.AUTH_GLP.GRANT_TYPE
  }, require('auth0-lock'));

});

app.controller('glpMainCtrl', ['$scope',
  '$state',
  '$localStorage',
  '$mdSidenav',
  '$mdDialog',
  'authService',
  '$translate',
  'clientService', function ($scope,
                             $state,
                             $localStorage,
                             $mdSidenav,
                             $mdDialog,
                             authService,
                             $translate,
                             clientService) {
    let vm = this;
    vm.toggleMenu = toggleMenu;
    vm.goHome = goHome;
    vm.logout = logout;
    vm.auth = authService;
    vm.title = null;
    vm.accept = null;
    vm.cancel = null;
    vm.user = null;

    if ($localStorage.user_role === 'ADMIN') {
      clientService.getUserInfo().then((response) => {
        if(response.data == "Unauthorized") {
            authService.logout();
          }
      })
      vm.user = $localStorage.user_role;
    }

    $scope.$on('userinfo', function () {
      if ($localStorage.user_role === 'ADMIN') {
          vm.user = $localStorage.user_role;
      }
      else {
        clientService.getUserInfo().then((response) => {
          if(response.data == "Unauthorized") {
            authService.logout();
            return;
          }
          else {
            clientService.setUserData(response.data);
            return clientService.getClient();
          }
        }).then((response) => {
          clientService.setClientData(response.data);
          vm.user = $localStorage.user_email;
        }).catch((err) => {
          console.warn(err);
        });
      }
    });

    function logout($event) {
      vm.title = $translate.instant("CONFIRM.TITLE_LOGOUT");
      vm.accept = $translate.instant("CONFIRM.YES");
      vm.cancel = $translate.instant("CONFIRM.NO");
      $mdDialog.show({
        templateUrl: './components/confirm/template.html',
        controller: dialogController,
        parent: angular.element(document.body),
        targetEvent: $event,
        ariaLabel: 'Confirmation alert',
        clickOutsideToClose: true,
        locals: {
          title: vm.title,
          accept: vm.accept,
          cancel: vm.cancel
        }
      }).then((answer) => {
        if (answer == 'save') {
          authService.logout();
        }
      })
    }

    function dialogController($scope, $mdDialog, title, accept, cancel) {
      $scope.title = title;
      $scope.accept = accept;
      $scope.cancel = cancel;
      $scope.answer = function (answer) {
        $mdDialog.hide(answer);
      }
    }

    function toggleMenu() {
      $mdSidenav('glpMainNav').toggle();
    }

    function goHome() {
      $state.go('home.bills');
    }

  }]);

angular.bootstrap(document.getElementById('appContainer'), [CONFIG.APP_NAME]);

angular.element(document).ready(function () { if ( window.self !== window.top ) { document.body.innerHTML="<p> ERROR </p>"; } else { document.body.style.display ="block" } });