const app = angular.module(CONFIG.APP_NAME);

const authService = require('./authorization');
authService.$inject = ['$state', '$localStorage', 'angularAuth0', '$q'];
app.service(CONFIG.SERVICES.AUTHSERVICE, authService);

const billService = require('./bill');
billService.$inject = ['$q', '$http', '$localStorage'];
app.service(CONFIG.SERVICES.BILL, billService);

const contract = require('./contract');
contract.$inject = ['$q', '$http', '$localStorage'];
app.service(CONFIG.SERVICES.CONTRACT, contract);

const client = require('./client');
client.$inject = ['$q', '$http', '$localStorage', 'angularAuth0'];
app.service(CONFIG.SERVICES.CLIENT, client);

module.exports = {};