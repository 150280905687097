const angular = require('angular');

const billCtrl = require('./controller');
billCtrl.$inject = ['$translate', 'billService', '$state', '$localStorage'];

module.exports = angular.module(CONFIG.APP_NAME).component(CONFIG.COMPONENTS.BILL, {
  bindings: {
    bill: '<'
  },
  transclude: true,
  controller: billCtrl,
  templateUrl: './components/bill/template.html'
});