'use strict';

function clientCtrl($scope, $localStorage, $state, $translate, $mdDialog, clientService) {
  $scope.$emit('userinfo');
  this.showConfirm_save = showConfirm_save;
  this.showConfirm_language = showConfirm_language;
  this.showConfirm_canceled = showConfirm_canceled;
  this.callUpdatePassword = callUpdatePassword.bind(this);
  this.cancelAccount = cancelAccount;
  this.atr = false;
  this.passwordSuccess = false;

  this.loading = false;

  /**
  * On initialisation checks if it is an admin user
  */
  this.$onInit = function () {
    if($localStorage.user_role === 'ADMIN') {
      this.atr = true;
    }
  }

  this.$onChanges = function () {
  }

  this.language = $translate.use();
  this.showPassword = false;
  this.password = null;
  this.confirmPassword = null;
  this.error = false;
  this.errorMessages = [];
  this.hide = true;

  if($localStorage.user_role === 'CLIENT') {
    this.email = $localStorage.user_email;
    this.name = $localStorage.client_name + ' ' + $localStorage.client_lastname;
    this.created = $localStorage.user_created_at;
    this.picture = $localStorage.user_picture;

  } else {
    this.email = '';
    this.name = $localStorage.user_role;
    this.created = '';
    this.picture = 'assets/img/profile-image.png';
    this.atr = true;

  }

  /**
  * change password
  */
  this.changePassword = function () {
    this.errorMessages = [];
    if (this.password != this.confirmPassword || this.password == null || this.password == "" || this.confirmPassword == null || this.confirmPassword == "") {
      this.error = true;
      if (this.password != this.confirmPassword) {
        this.errorMessages.push($translate.instant("CLIENT.PASSWORD_MATCH"));
      } else {
        this.errorMessages.push($translate.instant("CLIENT.PASSWORD_EMPTY"));
      }
      this.hide = false;
    }
    else {
      if(this.password.length < 6) {
        this.error = true;
        this.errorMessages.push($translate.instant("CLIENT.PASSWORD_LENGTH"));
        this.hide = false;
      }
      else {
        this.error = false;
        this.errorMessages = [];
        this.hide = true;
        this.showConfirm_save();
      }
    }
  }

  /*
  * Show change password inputs
  */
  this.openChangePassword = function() {
    this.pwd = !this.pwd;
    this.password=null;
    this.confirmPassword=null;
    this.error = false;
    this.errorMessages = [];
    this.passwordSuccess = false;
  }

  /**
  * hide change password inputs
  */
  this.cancelChangePassword = function() {
    this.pwd = !this.pwd;
    this.password = null;
    this.confirmPassword = null;
    this.error = false;
    this.errorMessages = [];
  }

  /**
  * show confirmation dialog
  */
  function showConfirm_save($event) {
    this.title = $translate.instant("CONFIRM.TITLE_SAVE");
    this.accept = $translate.instant("CONFIRM.SAVE");
    this.cancel = $translate.instant("CONFIRM.CANCEL");
    $mdDialog.show({
      templateUrl: './components/confirm/template.html',
      controller: dialogController,
      parent: angular.element(document.body),
      targetEvent: $event,
      ariaLabel: 'Confirmation alert',
      clickOutsideToClose: true,
      locals: {
        title: this.title,
        accept: this.accept,
        cancel: this.cancel
      }
    }).then((answer) => {
      if (answer == 'save') {
        this.error = false;
        this.errorMessages = [];
        this.callUpdatePassword();
      }
      else {
        this.cancelChangePassword();
      }

    })
    function dialogController($scope, $mdDialog, title, accept, cancel) {
      $scope.title = title;
      $scope.accept = accept;
      $scope.cancel = cancel;
      $scope.answer = function (answer) {
        $mdDialog.hide(answer);
      }
    }
  }

  /**
  * Cancel account
  */
  function cancelAccount($event) {
    this.title = $translate.instant("CONFIRM.TITLE_CANCEL");
    this.accept = $translate.instant("CONFIRM.YES");
    this.cancel = $translate.instant("CONFIRM.NO");
    $mdDialog.show({
      templateUrl: './components/confirm/template.html',
      controller: dialogController,
      parent: angular.element(document.body),
      targetEvent: $event,
      ariaLabel: 'Confirmation alert',
      clickOutsideToClose: true,
      locals: {
        title: this.title,
        accept: this.accept,
        cancel: this.cancel
      }
    }).then((answer) => {
      if (answer == 'save') {
        this.callDeleteAccount();
      }
    })
    function dialogController($scope, $mdDialog, title, accept, cancel) {
      $scope.title = title;
      $scope.accept = accept;
      $scope.cancel = cancel;
      $scope.answer = function (answer) {
        $mdDialog.hide(answer);
      }
    }
  }

  /**
  * Call delete account service
  */
  this.callDeleteAccount = function () {
    this.loading = true;
    clientService.deleteUser().then((response) => {
      this.loading = false;
      if (response.status === 200) {
        this.showConfirm_canceled();
      }
    }).catch((err) => {
      this.loading = false;
      // TODO
    });
  }

  /**
  * show account cancelle confirmation dialog
  */
  function showConfirm_canceled($event) {
    this.title = $translate.instant("CONFIRM.TITLE_CANCELED");
    this.accept = $translate.instant("CONFIRM.RETURN_LOGIN");
    $mdDialog.show({
      templateUrl: './components/confirm/template2.html',
      controller: dialogController,
      parent: angular.element(document.body),
      targetEvent: $event,
      ariaLabel: 'Confirmation alert',
      clickOutsideToClose: false,
      locals: {
        title: this.title,
        accept: this.accept
      }
    }).then((answer) => {
      this.removeLocalStorage();
      $state.go('login');
    })
    function dialogController($scope, $mdDialog, title, accept) {
      $scope.title = title;
      $scope.accept = accept;

      $scope.answer = function (answer) {
        $mdDialog.hide(answer);
      }
    }
  }

  /**
  * change language
  */
  this.changeLanguage = function(ev) {
    if (this.language) {
      this.showConfirm_language();
    }
  }

  /**
  * show change language confirmation dialog
  */
  function showConfirm_language($event, labels) {
    this.title = $translate.instant("CONFIRM.TITLE_LANGUAGE");
    this.accept = $translate.instant("CONFIRM.YES");
    this.cancel = $translate.instant("CONFIRM.NO");
    $mdDialog.show({
      templateUrl: './components/confirm/template.html',
      controller: dialogController,
      parent: angular.element(document.body),
      targetEvent: $event,
      ariaLabel: 'Confirmation alert',
      clickOutsideToClose: true,
      locals: {
        title: this.title,
        accept: this.accept,
        cancel: this.cancel
      }
    }).then((answer) => {
      if (answer == 'save') $translate.use(this.language);
      else this.language = $translate.use();
    })
    function dialogController($scope, $mdDialog, title, accept, cancel) {
      $scope.title = title;
      $scope.accept = accept;
      $scope.cancel = cancel;
      $scope.answer = function (answer) {
        $mdDialog.hide(answer);
      }
    }
  }

  /**
  * call update password service
  */
  function callUpdatePassword() {
    let password = this.password;
    this.loading = true;
    clientService.updatePassword(password).then((response) => {
        this.loading = false;
        this.pwd = !this.pwd;
        this.error = true;
        this.hide = false;
        this.errorMessages.push($translate.instant("CLIENT.PASSWORD_SUCCESSFUL"));
        this.passwordSuccess = true;
    }).catch((err) => {
        this.loading = false;
    });
  }

  /**
  * remove local storage
  */
  this.removeLocalStorage = function () {
    $localStorage.$reset();
  }

  /**
  * check if enter key is pressed
  */
  this.checkEnterPress = function($event) {
    var keyCode = $event.which || $event.keyCode;
    if(keyCode === 13) {
      this.changePassword();
    }
  }

}

module.exports = clientCtrl;