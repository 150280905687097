// Login controller
'use strict';

function loginCtrl($state,
                   $localStorage,
                   authService,
                   $translate,
                   clientService,
                   $q) {

  var def = $q.defer();
  this.auth = authService;
  this.client = {};
  this.error = false;
  this.errorMessages = [];
  this.emailText = $translate.instant('LOGIN.EMAIL');
  this.passwordText = $translate.instant('LOGIN.PASSWORD');

  this.legalInfo = CONFIG.ENDPOINTS.LEGAL_INFORMATION;
  this.client.emailPassword = null;
  this.status = 'login';

  this.loading = false;

  this.clientService = clientService;

  this.$onInit = function () {
  }

  this.$onChanges = function () {
  }

  /**
   * validate form inputs and get if client exist.
   *
   * @return {undefined}
   */
  this.login = function () {
    this.error = false;
    if (this.client.username && this.client.password) {
      this.loading = true;
      this.clientService.login2(this.client.username, this.client.password).then((resolve) => {
        return this.clientService.getUserInfo();
      }).then((response) => {
        this.clientService.setUserData(response.data);
        this.loading = false;
        $state.go('home');
        def.resolve(response);
      }).catch((err) => {
        this.loading = false;
        this.error = true;
        let errorMessage;

        if (err.code === null) {
          errorMessage = 'LOGIN.UNDEFINED_ERROR';
        } else {
          errorMessage = ' LOGIN.' + err.code.toUpperCase();
        }

        this.errorMessages = [errorMessage];
      });

    } else {
      this.error = true;
      this.errorMessages = [$translate.instant('LOGIN.MANDATORY_FIELDS')];
    }
  }

  /**
  * validate form inputs and get if client exists
  */
  this.login2 = function () {
    if (this.client.username && this.client.password) {
      this.loading = true;
      clientService.Login(this.client.username, this.client.password).then((response) => {
        this.loading = false;
        $state.go('home');
      }).catch((err) => {
        this.loading = false;
        this.error = true;
        let errorMessage;
        if (err.data.error === null) {
          errorMessage = 'LOGIN.UNDEFINED_ERROR';
        } else {
          errorMessage = ' LOGIN.' + err.data.error.toUpperCase();
        }
        this.errorMessages = [errorMessage];
      });
    } else {
      this.error = true;
      this.errorMessages = [$translate.instant('LOGIN.MANDATORY_FIELDS')];
    }
  }

  /**
   * Go to registration state
   *
   *@return {undefined}
   */
  this.goToRegistration = function (ev) {
    ev.preventDefault();
    $state.go('registration');
  }

  /**
  * go to restore password state
  */
  this.goToRestorePassword = function (ev) {
    ev.preventDefault();
    $state.go('restorePassword');
  }

  /**
  * open legal info link
  */
  this.openLegalInfo = function () {
    window.open(this.legalInfo, '_blank');
  }

}

module.exports = loginCtrl;
