'use strict';

function confirmCtrl ($mdDialog, title, accept, cancel) {
	this.title = title;
	this.accept = accept;
	this.cancel = cancel;

	this.$onInit = function() {
	};

	this.$onChanges = function () {
	}

	/**
	* hide dialog
	*/
	this.answer = function (answer) {
		$mdDialog.hide(answer);
	}

}

module.exports = confirmCtrl;