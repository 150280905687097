'use strict';

function navCtrl($state, $translate, $mdSidenav) {

  this.$onInit = function () {
  }

  this.$onChanges = function () {
  }

  this.isActive = function(state) {
    return state === $state.current.name;
  }

  this.nav = function (state) {
    $state.go(state);
    $mdSidenav('glpMainNav').close();
  }

}

module.exports = navCtrl;