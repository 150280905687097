'use strict';

function footerCtrl($translate, authService) {
  this.auth = authService;
  this.legalInfo = CONFIG.ENDPOINTS.LEGAL_INFORMATION;

  /**
  * Open legal info link
  */
  this.openLegalInfo = function() {
  	window.open(this.legalInfo, '_blank');
  }
}

module.exports = footerCtrl;