'use strict';

function billCtrl($translate, billService, $state, $localStorage) {
  this.bill = null;
  this.billPdf = null;
  this.getBillAsPDF = getBillAsPDF;
  this.getBillAsPDFUser = getBillAsPDFUser.bind(this);
  this.getBillAsPDFATR = getBillAsPDFATR.bind(this);
  this.clientId = null;

  this.loading = false;
  /**
   * gets values from component binding
   *
   *@return {undefined}
   */
  this.$onInit = function () {
    this.bill = this.bill;
  }

  /**
   * detects changes on values from component binding
   *
   *@return {undefined}
   */
  this.$onChanges = function () {
    this.bill = this.bill;
  }


  /**
  * Calls get bills as pdf functions (for user and admin)
  */
  function getBillAsPDF() {
    if($localStorage.user_role === 'ADMIN') {
      this.clientId = $localStorage.admin_search;
      this.getBillAsPDFATR();
    }
    else this.getBillAsPDFUser();
  }


  /**
  * Calls getBillAsPDF service and downloads pdf for users
  */
  function getBillAsPDFUser() {
    this.loading = true;
    billService.getBillAsPDF(this.bill.factura).then((response) => {
      this.loading = false;
      if (response.data.contenido) {
        if(window.navigator.appVersion.toString().indexOf('.NET') > 0) {
          var byteCharacters = atob(response.data.contenido);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {type: 'application/pdf'} );
          window.navigator.msSaveOrOpenBlob(blob, response.data.nombre + '.pdf');
        } else {
          var link = document.createElement('a');
          var uri = 'data:application/pdf;base64,' + response.data.contenido;
          link.download = response.data.nombre;
          link.href = uri;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } else {
        console.warn('No se ha podido cargar el contenido');
      }
    }).catch((err) => {
      this.loading = false;
      console.warn(err);
    });
  }

  /**
  * Calls getBillAsPDFByCLientId service and download pdf for admins
  */
  function getBillAsPDFATR() {
    this.loading = true;
    billService.getBillAsPDFByClientId(this.clientId, this.bill.factura).then((response) => {
      this.loading = false;
      if (response.data.contenido) {
        if(window.navigator.appVersion.toString().indexOf('.NET') > 0) {
          var byteCharacters = atob(response.data.contenido);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {type: 'application/pdf'} );
          window.navigator.msSaveOrOpenBlob(blob, response.data.nombre + '.pdf');
        } else {
          var link = document.createElement('a');
          var uri = 'data:application/pdf;base64,' + response.data.contenido;
          link.download = response.data.nombre;
          link.href = uri;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } else {
        console.warn('No se ha podido cargar el contenido');
      }
    }).catch((err) => {
      this.loading = false;
      console.warn(err);
    });
  }

  /**
  * Goes to route home.contracts
  */
  this.goToContract = function(ev) {
    ev.preventDefault();
    $localStorage.current_contract = this.bill.contrato;
    $state.go('home.contracts');
  }

}

module.exports = billCtrl;