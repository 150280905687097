module.exports={
  "//": "This is an automatic translation. Help us to improve it.",
  "loadingTitle":                   "Подождите...",
  "close":                          "Закрыть",
  "windowsAuthTitle":               "Windows аутентификация",
  "invalid":                        "Неверный",
  "mustMatch":                      "Должны совпадать",
  "loginSocialButton":              "Вход через {connection:title}",
  "signupSocialButton":             "Регистрация через {connection:title}",
  "noConnectionError":              "Нет действительна соединение не может быть найдено.",
  "signin": {
    "title":                        "Войти",
    "action":                       "Войти",
    "all":                          "Показать все",
    "strategyEmailEmpty":           "Email пустой.",
    "strategyEmailInvalid":         "Email недействителен.",
    "strategyDomainInvalid":        "Домен {domain} не был установлен.",
    "signinText":                   "Войти",
    "signupText":                   "Регистрация",
    "forgotText":                   "Забыли ваш пароль?",
    "emailPlaceholder":             "Email",
    "usernamePlaceholder":          "Имя пользователя",
    "passwordPlaceholder":          "Пароль",
    "separatorText":                "или",
    "serverErrorText":              "Произошла ошибка при обработке входа.",
    "returnUserLabel":              "Последний раз вы заходили через...",
    "wrongEmailPasswordErrorText":  "Неправильный email или пароль.",
    "or":                           "... или войдите с помощью",
    "loadingMessage":               "Вход через {connection}..."
  },
  "signup": {
    "description":                  "",
    "title":                        "Регистрация",
    "action":                       "Регистрация",
    "signinText":                   "Войти",
    "signupText":                   "Регистрация",
    "emailPlaceholder":             "Email",
    "passwordPlaceholder":          "Создайте пароль",
    "cancelAction":                 "Отмена",
    "headerText":                   "Пожалуйста, введите свой email и пароль",
    "footerText":                    "",
    "signupOnSSODomainErrorText":   "Этот домен {domain} был настроен для единого входа и нельзя создать учетную запись, попробуйте войти.",
    "serverErrorText":              "Произошла ошибка при обработке регистрации.",
    "userExistsErrorText":          "Пользователь существует."
  },
  "newReset": {
    "title":                        "Сброс пароля",
    "action":                       "Отправить",
    "emailPlaceholder":             "Email",
    "cancelAction":                 "Отмена",
    "footerText":                   "",
    "successText":                  "Мы отправили вам email для сброса пароля.",
    "headerText":                   "Пожалуйста, введите Ваш адрес электронной почты. Мы вышлем Вам по электронной почте для сброса пароля.",
    "serverErrorText":              "Произошла ошибка при обработке сброса пароля.",
    "userDoesNotExistErrorText":    "Пользователь не существует."
  },
  "reset": {
    "title":                        "Смена пароля",
    "action":                       "Отправить",
    "emailPlaceholder":             "Email",
    "passwordPlaceholder":          "Новый пароль",
    "repeatPasswordPlaceholder":    "Подтвердите новый пароль",
    "cancelAction":                 "Отмена",
    "footerText":                   "",
    "successText":                  "Мы отправили вам email для сброса пароля.",
    "enterSamePasswordText":        "Пожалуйста, введите тот же пароль.",
    "headerText":                   "Пожалуйста, введите ваш email и новый пароль. Мы вышлем email для подтверждения изменения пароля.",
    "serverErrorText":              "Произошла ошибка при обработке сброса пароля.",
    "userDoesNotExistErrorText":    "Пользователь не существует.",
    "invalidPassword":              "Пароль слишком слаб."
  }
}
