'use strict';

function authService($state, $localStorage, angularAuth0, $q, $http) {

  function setSession(authResult) {
    // Set the time that the access token will expire at
    //let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    $localStorage.access_token = authResult.accessToken;
    $localStorage.id_token = authResult.idToken;
    //localStorage.setItem('expires_at', expiresAt);
  }

  function logout() {
    // Remove tokens and expiry time from localStorage
    $localStorage.$reset();
    $state.go('login');
  }

  function isAuthenticated() {
    if($localStorage.access_token && $localStorage.id_token && $localStorage.user_role ) {
      return true;
    } else {
      return false;
    }
  }

  return {
    setSession: setSession,
    logout: logout,
    isAuthenticated: isAuthenticated
  }

}

module.exports = authService;