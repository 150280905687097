// Login controller
'use strict';

function restorePasswordCtrl($state,
                   $translate,
                   clientService,
                   $mdDialog) {

  this.showConfirm_password = showConfirm_password;
  this.showConfirm_wrongPassword = showConfirm_wrongPassword;
  this.email = null;
  this.error = false;
  this.errorMessages = [];
  this.send = send;
  this.emailText = $translate.instant("PASSWORD.EMAIL");

  this.loading = false;

  this.$onInit = function () {
  }

  this.$onChanges = function () {
  }

  /**
  * call change password service
  */
  function send() {
    if (this.email != null && this.email != "") {
      this.loading = true;
      clientService.changePassword(this.email).then((response) => {
        this.loading = false;
        if (response.status === 201 || response.status === 200) {
          this.showConfirm_password();
        }
      }).catch((err) => {
        if(err.status === 403) {
          this.showConfirm_wrongPassword();
        }
        else {
          this.loading = false;
          console.warn(err);
        }
      });
    }
    else {
      this.error = true;
      this.errorMessages = [$translate.instant('LOGIN.MANDATORY_FIELDS')];
    }

  }

  /**
  * show email change password confirmation dialog
  */
  function showConfirm_password($event) {
    this.title = $translate.instant("CONFIRM.TITLE_PASSWORD");
    this.accept = $translate.instant("CONFIRM.RETURN_LOGIN");
    $mdDialog.show({
      templateUrl: './components/confirm/template2.html',
      controller: dialogController,
      parent: angular.element(document.body),
      targetEvent: $event,
      ariaLabel: 'Confirmation alert',
      clickOutsideToClose: true,
      locals: {
        title: this.title,
        accept: this.accept
      }
    }).then((answer) => {
      if (answer == 'save') {
        this.error = false;
        this.errorMessages = [];
        this.goToLogin();
      }
      else {
        this.error = false;
        this.errorMessages = [];
      }
    })
    function dialogController($scope, $mdDialog, title, accept) {
      $scope.title = title;
      $scope.accept = accept;

      $scope.answer = function (answer) {
        $mdDialog.hide(answer);
      }
    }
  }

  /**
  * show wrong email confirmation dialog
  */
  function showConfirm_wrongPassword($event) {
    this.title = $translate.instant("CONFIRM.TITLE_WRONGPASSWORD");
    this.accept = $translate.instant("CONFIRM.RETURN_LOGIN");
    $mdDialog.show({
      templateUrl: './components/confirm/template2.html',
      controller: dialogController,
      parent: angular.element(document.body),
      targetEvent: $event,
      ariaLabel: 'Confirmation alert',
      clickOutsideToClose: true,
      locals: {
        title: this.title,
        accept: this.accept
      }
    }).then((answer) => {
      if(answer == 'save') {
        this.error = false;
        this.errorMessages = [];
        this.goToLogin();
      }
      else { 
        this.error = false;
        this.errorMessages = [];
      }
    })
    function dialogController($scope, $mdDialog, title, accept) {
      $scope.title = title;
      $scope.accept = accept;

      $scope.answer = function (answer) {
        $mdDialog.hide(answer);
      }
    }
  }

  /**
  * go to login state
  */
  this.goToLogin = function() {
    $state.go('login');
  }

}

module.exports = restorePasswordCtrl;