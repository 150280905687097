module.exports={
  "loadingTitle":                  "기다려주세요...",
  "close":                         "닫기",
  "windowsAuthTitle":              "Windows Authentication",
  "invalid":                       "유효하지 않음",
  "mustMatch":                     "일치하지 않음",
  "loginSocialButton":             "{connection:title} (으)로 로그인",
  "signupSocialButton":            "{connection:title} (으)로 회원가입",
  "networkError":                  "서버에 연결할 수 없습니다.<br/>다시 시도해 주세요.",
  "noConnectionError":             "유효한 연결을 찾을 수 없습니다.",
  "signin": {
    "title":                       "로그인",
    "action":                      "로그인",
    "actionDomain":                " {domain}",
    "all":                         "Show all",
    "strategyEmailEmpty":          "이메일을 입력해 주세요.",
    "strategyEmailInvalid":        "이메일이 유효하지 않습니다.",
    "strategyDomainInvalid":       "The domain {domain} has not been set up.",
    "signinText":                  "로그인",
    "signupText":                  "회원가입",
    "forgotText":                  "비밀번호 찾기",
    "cancelAction":                "취소",
    "footerText":                  "",
    "emailPlaceholder":            "이메일",
    "usernamePlaceholder":         "아이디",
    "passwordPlaceholder":         "비밀번호",
    "separatorText":               "또는",
    "serverErrorText":             "로그인 중에 문제가 발생했습니다.",
    "returnUserLabel":             "Last time you signed in using...",
    "domainUserLabel":             "You are connected from your corporate network...",
    "wrongEmailPasswordErrorText": "잘 못된 이메일 또는 비밀번호 입니다..",
    "or":                          "... or log in using",
    "loadingMessage":              "{connection} (으)로 로그인 중...",
    "popupCredentials":            "팝업창에 로그인 정보를 입력해 주세요.",
    "userClosedPopup":             "팝업창이 닫혔습니다. 다시 시도해 주세요.",
    "userConsentFailed":           "권한이 없습니다. 다시 시도해 주세요."
  },
  "signup": {
    "description":                 "",
    "title":                       "회원가입",
    "action":                      "가입",
    "signinText":                  "로그인",
    "signupText":                  "회원가입",
    "emailPlaceholder":            "이메일",
    "usernamePlaceholder":         "아이디",
    "passwordPlaceholder":         "비밀번호",
    "cancelAction":                "취소",
    "headerText":                  "이메일과 비밀번호를 입력해 주세요",
    "footerText":                  "",
    "serverErrorText":             "회원가입 중에 문제가 발생했습니다.",
    "userExistsErrorText":         "이미 가입한 회원입니다.",
    "signupOnSSODomainErrorText":  "This domain {domain} has been configured for Single Sign On and you can't create an account. Try signing in instead.",
    "usernameInUseErrorText":      "이미 사용중인 아이디입니다..",
    "invalidPassword":             "비밀번호가 유효하지 않습니다.",

    "passwordStrength": {
      "nonEmpty": "비밀번호를 입력해 주세요",
      "lengthAtLeast": "최소 #d 자 이상",
      "shouldContain": "포함해야함:",
      "containsAtLeast" : "적어도 다음 %d 가지 중 % 개를 포함해야함: ",
      "lowerCase": "영어 소문자 (a-z)",
      "upperCase": "영어 대문자 (A-Z)",
      "numbers": "숫자 (i.e. 0-9)",
      "specialCharacters" : "특수 문자 (e.g. !@#$%^&*)",
      "identicalChars": "No more than %d identical characters in a row (e.g., \"%s\" not allowed)"
    }

  },
  "newReset": {
    "title":                       "암호를 재설정",
    "action":                      "전송",
    "emailPlaceholder":            "이메일",
    "cancelAction":                "취소",
    "footerText":                  "",
    "successText":                 "비밀번호 변경을 위한 이메일을 전송하였습니다.",
    "headerText":                  "당신의 이메일 주소를 입력하십시오. 우리는 당신에게 비밀번호를 재설정 할 수있는 이메일을 보내드립니다.",
    "serverErrorText":             "비밀번호 변경 중에 문제가 발생했습니다.",
    "userDoesNotExistErrorText":   "존재하지 않는 사용자 입니다."
  },
  "reset": {
    "title":                       "비밀번호 변경",
    "action":                      "전송",
    "emailPlaceholder":            "이메일",
    "passwordPlaceholder":         "새 비밀번호",
    "repeatPasswordPlaceholder":   "새 비밀번호 확인",
    "cancelAction":                "취소",
    "footerText":                  "",
    "successText":                 "비밀번호 변경을 위한 이메일을 전송하였습니다.",
    "enterSamePasswordText":       "동일한 비밀번호를 입력해 주세요.",
    "headerText":                  "이메일과 새 비밀번호를 입력해 주세요. 비밀번호 변경을 위해 이메일을 전송합니다.",
    "serverErrorText":             "비밀번호 변경 중에 문제가 발생했습니다.",
    "userDoesNotExistErrorText":   "존재하지 않는 사용자 입니다.",
    "invalidPassword":             "비밀번호가 유효하지 않습니다."
  }
}
