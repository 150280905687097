module.exports={
  "loadingTitle":                  "Por favor espere...",
  "close":                         "Cerrar",
  "invalid":                       "Inválido",
  "mustMatch":                     "Debe coincidir",
  "loginSocialButton":             "Ingresar con {connection:title}",
  "signupSocialButton":            "Registrarse con {connection:title}",
  "noConnectionError":             "No se pudo encontrar una conexión válida.",
  "signin": {
    "title":                       "Iniciar sesión",
    "action":                      "Ingresar",
    "all":                         "Mostrar todas",
    "strategyEmailEmpty":          "El email está vacío.",
    "strategyEmailInvalid":        "El email no es válido.",
    "strategyDomainInvalid":       "El dominio {domain} no está configurado.",
    "signinText":                  "Iniciar sesión",
    "signupText":                  "Registrarse",
    "forgotText":                  "Olvidaste tu contraseña?",
    "emailPlaceholder":            "Email",
    "usernamePlaceholder":         "Nombre de usuario",
    "passwordPlaceholder":         "Contraseña",
    "separatorText":               "o",
    "serverErrorText":             "Hubo un error al iniciar sesión.",
    "returnUserLabel":             "La última vez inició sesión con...",
    "domainUserLabel":             "Usted se encuentra conectado desde su red corporativa...",
    "wrongEmailPasswordErrorText": "Email o clave incorrecta.",
    "or":                          "... o inicie sesión usando",
    "loadingMessage":              "Iniciando sesión con {connection}...",
    "popupCredentials":            "Ingrese sus credenciales en la ventana emergente"
  },
  "signup": {
    "description":                 "",
    "title":                       "Registrarse",
    "action":                      "Registrar",
    "signinText":                  "Iniciar sesión",
    "signupText":                  "Registrarse",
    "emailPlaceholder":            "Email",
    "passwordPlaceholder":         "Contraseña",
    "cancelAction":                "Cancelar",
    "headerText":                  "Por favor ingrese email y contraseña",
    "footerText":                  "",
    "signupOnSSODomainErrorText":  "El dominio {domain} ha sido configurado para Single Sign On y no puede crear una cuenta. Intente iniciar sesión directamente.",
    "serverErrorText":             "Hubo un error al procesar su registro.",
    "userExistsErrorText":         "El usuario ya existe."
  },
  "newReset": {
    "title":                       "Restablecer Contraseña",
    "action":                      "Enviar",
    "emailPlaceholder":            "Email",
    "cancelAction":                "Cancelar",
    "footerText":                  "",
    "successText":                 "Hemos enviado un email para completar el restablecimiento de su contraseña.",
    "headerText":                  "Por favor ingrese su email. Enviaremos un email para realizar el cambio.",
    "serverErrorText":             "Hubo un error al restablecer su contraseña.",
    "userDoesNotExistErrorText":   "Usuario inválido."
  },
  "reset": {
    "title":                       "Restablecer Contraseña",
    "action":                      "Enviar",
    "emailPlaceholder":            "Email",
    "passwordPlaceholder":         "Nueva Contraseña",
    "repeatPasswordPlaceholder":   "Confirme nueva contraseña",
    "cancelAction":                "Cancelar",
    "footerText":                  "",
    "successText":                 "Hemos enviado un email para completar el restablecimiento de su contraseña.",
    "enterSamePasswordText":       "Por favor ingrese la misma contraseña.",
    "headerText":                  "Por favor ingrese su email y la nueva contraseña. Enviaremos un email para confirmar el cambio.",
    "serverErrorText":             "Hubo un error al restablecer su contraseña.",
    "userDoesNotExistErrorText":   "Usuario inválido.",
    "invalidPassword":             "La contraseña ingresada es muy débil."
  }
}
